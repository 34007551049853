<template>
  <div>
    <div class="s-menu">
      <div class="d-flex container">
        <div class="col-3">
          <a href="/">
            <img src="@/assets/images/enseada-blue.png" alt="..." />
          </a>
          <a
            href="https://starmaisbeneficios.wixsite.com/starmaisbeneficios"
            target="blank"
          >
            <img src="@/assets/images/logo_StarFort.jpeg" alt="..." />
          </a>
        </div>
        <div class="col-9">
          <ul class="menu-home font appColor-blue">
            <li class="lmenu">
              <a href="../detalhes-categoria">Promo</a>
            </li>
            <li class="lmenu">
              <a href="../detalhes-categoria">Pacotes</a>
            </li>
            <li class="lmenu">
              <a href="#">A Enseada</a>
            </li>
            <li class="lmenu">
              <a href="#">Novidades</a>
            </li>
            <li class="lmenu">
              <p><a href="../login">Entre ou Cadastre-se</a></p>
            </li>
            <li class="lmenu">
              <div class="dropdown">
                <a
                  class=" dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Minha Conta
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li>
                    <a class="dropdown-item" @click="alterar_dados"
                      >Alterar Dados</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" @click="meus_pedidos"
                      >Minhas Viagens</a
                    >
                  </li>
                  <li><a class="dropdown-item" @click="logout">Sair</a></li>
                </ul>
              </div>
            </li>
            <li>
              <v-btn elevation="0" @click.stop="abrirCarrinho = !abrirCarrinho">
                <v-badge color="#2b3a8f" :content="quantidade_produtos">
                  <v-icon class="appColor-secondary icon-font-size"
                    >mdi-basket</v-icon
                  >
                </v-badge>
              </v-btn>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <v-navigation-drawer
      class="modal-carrinho"
      width="50%"
      v-model="abrirCarrinho"
      fixed
      right
      temporary
    >
      <carrinho-template @carrinhoModal="abrirCarrinho = !abrirCarrinho" />
    </v-navigation-drawer>
  </div>
</template>

<script>
//import AuthService from "@/services/auth_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  data: () => ({
    abrirCarrinho: false,
  }),
  components: {
    carrinhoTemplate: () =>
      import("@/components/carrinho_modal/carrinho_template.vue"),
  },
  computed: {
    quantidade_produtos() {
      return this.$store.state.carrinho.length || 0;
    },

    user: () => this.$store.state.usuario,
  },
  methods: {
    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },
    alterar_dados() {
      this.$router.replace("alterar-dados");
    },
    meus_pedidos() {
      this.$router.replace("meus-pedidos");
    },
    isGuest() {
      return !this.$store.state.token;
    },
    autenticarLogin() {
      let token = this.$_GETTERS_token.length;
      if (token == "") {
        return this.$router.replace("login");
      } else {
        return this.$router.replace("meus-pedidos");
      }
    },
  },
};
</script>

<style scoped>
.s-menu {
  background: transparent linear-gradient(180deg, #434343 -50%, #cfcfcf00 65%)
    0% 0% no-repeat padding-box;
}
li:hover ul {
  display: block;
}
.dropdown-item {
  color: #000 !important;
  background: #fff;
  text-align: right;
}
.menu-home {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin-top: 1rem;
}
.menu-home a {
  color: #fff;
  font-weight: 500;
}
@media (max-width: 600px) {
  .menu-home {
    margin-top: 0rem;
    justify-content: center;
  }
  .menu-home .it {
    margin: 1% 0% 1% 0%;
  }
  .bannercall {
    text-align: center;
    background-size: cover;
    padding: 3rem 3rem 3rem 3rem;
  }
  .lmenu {
    display: flex !important;
  }
}
</style>
