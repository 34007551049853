<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container-fluid">
        <a href="/">
          <img class="logo-image" src="@/assets/images/logo.png" alt="..." />
        </a>
        <a
          href="https://starmaisbeneficios.wixsite.com/starmaisbeneficios"
          target="blank"
        >
          <img
            class="logo-image-2"
            src="@/assets/images/logo_StarFort.jpeg"
            alt="..."
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          :aria-expanded="expanded"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          :class="` ${expanded ? 'collapse' : ''} navbar-collapse col-9`"
          id="navbarSupportedContent"
        >
          <ul
            class="navbar-nav me-auto mb-2 mb-lg-0 menu-home font appColor-white"
          >
            <!-- <li class="lmenu">
              <a aria-current="page" href="https://sistema.starfortseguros.com.br/login">Corretores</a>
            </li> -->
            <li class="lmenu">
              <a aria-current="page" href="../servicos">Seguros</a>
            </li>
            <li class="lmenu">
              <a href="../sobre">Conheça a Starfort</a>
            </li>
            <!-- <li class="lmenu">
              <a href="#enseada">A Enseada</a>
            </li>
            <li class="lmenu">
              <a href="#novidades">Novidades</a>
            </li> -->
            <div class="container-logado" v-if="usuarioLogado">
              <li class="lmenu">
                <a class=" item-menu-font" @click="irParaMeusSeguros"
                  >Meus Seguros</a
                >
              </li>
              <li class="lmenu">
                <a class="item-menu-font" @click="alterar_dados"
                  >Alterar Dados</a
                >
              </li>
              <li class="lmenu">
                <a class="item-menu-font" @click="logout">Sair</a>
              </li>
            </div>
            <li v-if="usuarioLogado == false" class="lmenu d-flex">
              <v-icon class="appColor-blue icon cart-icon">mdi-account </v-icon>
              <p class="text-left appColor-secondary login-text">
                Faça <a class="linkacess" href="../login">login</a><br />ou
                <a class="linkacess" href="../nova-conta">crie seu cadastro</a>
              </p>
            </li>
            <li class="lmenu dropdown">
              <!-- <a
                class="dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Minha Conta
              </a> -->
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" @click="alterar_dados"
                    >Alterar Dados</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" @click="meus_pedidos"
                    >Minhas Viagens</a
                  >
                </li>
                <li><a class="dropdown-item" @click="logout">Sair</a></li>
              </ul>
            </li>
            <li class=""></li>
          </ul>
        </div>
        <v-btn
          class="cart"
          elevation="0"
          @click.stop="abrirCarrinho = !abrirCarrinho"
        >
          <v-badge color="#2b3a8f" :content="quantidade_produtos">
            <v-icon class="appColor-blue icon-cart">mdi-cart </v-icon>
          </v-badge>
        </v-btn>
      </div>
    </nav>
    <div>
      <v-navigation-drawer
        class="modal-carrinho"
        width="50%"
        v-model="abrirCarrinho"
        fixed
        right
        temporary
      >
        <carrinho-template @carrinhoModal="abrirCarrinho = !abrirCarrinho" />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
//import AuthService from "@/services/auth_service";
import Mixin from "@/mixins/vuex_mixin.js";
import UsuarioService from "@/services/usuario_service.js";

export default {
  mixins: [Mixin],
  data: () => ({
    abrirCarrinho: false,
    expanded: true,
    usuarioLogado: null,
    carrinhoMobile: false,
  }),
  beforeCreate() {
    const usuarioService = new UsuarioService();

    usuarioService.getUsuarioData(
      {
        onSucess: (status) => {
          if (status === 200) {
            this.usuarioLogado = true;
          } else {
            /*this.$_ACTIONS_showSnackbarMessage({
            message:
              body.message || this.$global.messages.internalServerError,
            color: "error",
          });*/

            this.usuarioLogado = false;
          }
        },
        onError: () => {
          this.usuarioLogado = false;
          // this.$_ACTIONS_showSnackbarMessage({
          //   message: this.$global.messages.internalServerError,
          //   color: "error",
          // });
          // console.log("usuario logado: ", this.usuarioLogado);
        },
        onEnd: () => {
          this.loading = false;
        },
      },
      localStorage.id_usuario
    );
  },
  components: {
    carrinhoTemplate: () =>
      import("@/components/carrinho_modal/carrinho_template.vue"),
  },
  computed: {
    quantidade_produtos() {
      return this.$store.state.carrinho.length || 0;
    },

    user: () => this.$store.state.usuario,

    verificarUsuarioLogado() {
      console.log("usuarioLogado: ", this.usuarioLogado);

      return this.usuarioLogado;
    },
  },
  watch: {
    usuarioLogado() {
      console.log("usuarioLogado: ", this.usuarioLogado);
    },
  },
  methods: {
    alternarMenu() {
      this.expanded = this.expanded ? false : true;
    },

    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },

    irParaMeusSeguros() {
      this.$router.replace("meus-pedidos");
    },

    alterarSenha() {
      console.log("Alterar Senha");
    },

    criarConta() {
      this.$router.push({
        name: "nova-conta",
        params: { loginCompra: this.loginCompra },
      });
    },
    alterar_dados() {
      this.$router.replace("alterar-dados");
    },
    meus_pedidos() {
      this.$router.replace("meus-pedidos");
    },
    isGuest() {
      return !this.$store.state.token;
    },
    autenticarLogin() {
      let token = this.$_GETTERS_token.length;
      if (token == "") {
        return this.$router.replace("login");
      } else {
        return this.$router.replace("meus-pedidos");
      }
    },
  },
};
</script>

<style scoped>
.cart,
.cart:hover {
  background-color: transparent !important;
}
.linkacess {
  font-weight: 700 !important;
}
.icon {
  font-size: 2.3rem;
}
li:hover ul {
  display: block;
}
.dropdown-item {
  color: #000 !important;
  background: #fff;
  text-align: right;
}
.menu-home {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  margin-top: 1rem;
  width: 100%;
}
.menu-home a {
  color: #282828;
  font-weight: 500;
}

.login-text {
  font-size: 0.7rem;
}

.logo-image {
  width: 9rem;
  height: 3rem;
}

.logo-image-2 {
  width: 6rem;
  margin-left: 50px;
}

.icon-cart {
  font-size: 2.3rem;
}

.container-logado {
  width: 300px;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}

.item-menu-font {
  color: #282828 !important;
  font-weight: 500 !important;
}

@media (max-width: 600px) {
  .menu-home {
    display: block;
    margin-top: 0rem;
    justify-content: center;
  }
  .menu-home .it {
    margin: 1% 0% 1% 0%;
  }
  .bannercall {
    text-align: center;
    background-size: cover;
    padding: 3rem 3rem 3rem 3rem;
  }
  .d-flex {
    display: flex !important;
  }
  .cart {
    margin-top: 2rem;
    /* background-color: red !important; */
  }

  .container-logado {
    /* display: flex !important; */
    /* background-color: red !important; */
    /* flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important; */
    width: 200px;
    /* background-color: red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.87rem;
  }
}
</style>
