const global = {
  api: {
    baseURL: "https://starfortseguros.com.br/api/public",
    // baseURL: "https://starfort.prolins.com.br/api/public",
    // baseURL: "https://ff10-2804-7f7-e081-8c88-87b5-bf1-ace2-865a.ngrok-free.app/starfort_api/public",
  },

  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    // { id: 2, description: 'medico' },
    // { id: 3, description: 'assistente' },
    { id: 4, description: "cliente" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };
